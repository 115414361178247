<template>
  <esmp-input
    disabled
    :label="label"
    v-model="value"
    :options="options"
  />
</template>

<script>
export default {
  name: 'CounterView',
  props: {
    settings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      value: 1,
      options: {
        type: 'number',
        min: 1,
        max: 100,
      },
    };
  },
  computed: {
    label() {
      return this.settings.find((el) => el.techName === 'name').value;
    },
  },
};
</script>
